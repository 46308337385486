.spacer {
    flex: 1 1 auto !important;
}

::-webkit-scrollbar-trackhorizontal {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #8a8a8a;
    border-radius: 10px;
}